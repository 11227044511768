<template>
    <div>
      <b-card>
        <list-report-head :base-url="exportTrophyCIPServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="2">
          <template v-slot:projectNameSlot>
            {{ }}
          </template>
          {{ getReportTitle(circularYear) }}
        </list-report-head>
        <b-overlay :show="loading">
            <div class="py-2 px-2 text-center">
              <strong class="text-underline">({{ $n(1) }}) {{ $t('externalUser.export_product_sector') }} : {{ productName(listItem.product_service_sector_id) }}</strong>
            </div>

            <div class="py-2 px-2 text-center">
              <strong class="text-underline">{{ getReportSubTitle() }}</strong>
            </div>
          <b-row class="mt-3">
            <b-col sm="12">
              <b-table-simple class="mt-3"  hover small bordered responsive>
                <b-thead>
                  <b-tr>
                    <b-th class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th class="text-center align-top">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th class="text-center align-top">{{ $t('cip.candidate_org_name_address') }}</b-th>
                    <b-th class="text-center align-top">{{ $t('cip.applicant_nid_tin_bin') }}</b-th>
                    <b-th class="text-center align-top">{{ getExportInfoPrc(fiscalYearId) }}</b-th>
                    <b-th class="text-center align-top">{{ getExportInfoBbc(fiscalYearId) }}</b-th>
                    <!-- <b-th class="text-center align-top">{{ $t('exportTrophy.export_markets') }}</b-th> -->
                    <b-th class="text-center align-top">{{ $t('exportTrophy.entering_new_market') }}</b-th>
                    <b-th class="text-center align-top">{{ $t('cip.are_trade_disputes') }}</b-th>
                    <b-th class="text-center align-top">{{  $t('globalTrans.comments') }}</b-th>
                  </b-tr>
                  <b-tr>
                    <b-th class="text-center"></b-th>
                    <b-th class="text-center"></b-th>
                    <b-th class="text-center">{{ $n(1) }}</b-th>
                    <b-th class="text-center">{{ $n(2) }}</b-th>
                    <b-th class="text-center">{{ $n(3) }}</b-th>
                    <b-th class="text-center">{{ $n(4) }}</b-th>
                    <b-th class="text-center">{{ $n(5) }}</b-th>
                    <b-th class="text-center">{{ $n(6) }}</b-th>
                    <b-th class="text-center">{{ $n(7) }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="listData.length">
                    <b-tr v-for="(item, index) in listData" :key="'row-1-'+ index">
                      <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                      <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                      <b-td>
                        <span>
                          <strong>{{ currentLocale === 'en' ? item.name_en : item.name_bn }} </strong><br/>
                          {{ currentLocale === 'en' ? item.designation_en : item.designation_bn }} <br>
                          {{ $t('globalTrans.father_name') + ':' + (currentLocale === 'en' ? item.father_name_en : item.father_name_bn) }} <br>
                          {{ $t('globalTrans.mother_name') + ':' + (currentLocale === 'en' ? item.mother_name_en : item.mother_name_bn) }} <br>
                          {{ item.address ? AddressHelper.getCommonAddress(getPresentAddressData(item.address)) : '' }} <br>
                          <span class="text-underline">{{ $t('globalTrans.permanent_address') }}</span>
                          <template v-if="item.address">
                            {{ AddressHelper.getCommonAddress(getPermanentAddressData(item.address)) }}
                          </template>
                          <template v-else>N/A</template><br>
                          {{ $t('globalTrans.phone_no') }}:
                          <template v-if="item.contact_info && item.contact_info.telephone_no">
                            {{ item.contact_info.telephone_no | numberConvert }}
                          </template>
                          <template v-else>N/A</template>
                          <br>
                          {{ $t('globalTrans.mobile') }}:
                          <template v-if="item.contact_info && item.contact_info.mobile_no">
                            {{ item.contact_info.mobile_no | numberConvert }}
                          </template>
                          <template v-else>N/A</template><br>
                          {{ $t('globalTrans.email') }}:
                          <template v-if="item.contact_info && item.contact_info.email">
                            {{ item.contact_info.email }}
                          </template>
                          <template v-else>N/A</template>
                          <br>
                          <strong>{{ currentLocale === 'en' ? item.org_info.org_name_en : item.org_info.org_name_bn }}</strong><br>
                          {{ item.org_address ? AddressHelper.getCommonAddress(item.org_address) : '' }} <br>
                          {{ $t('globalTrans.phone_no') }}:
                          <template v-if="item.office_contact_info && item.office_contact_info.telephone_no">
                            {{ item.office_contact_info.telephone_no | numberConvert }}
                          </template>
                          <template v-else>N/A</template>
                          <br>
                          {{ $t('globalTrans.mobile') }}:
                          <template v-if="item.office_contact_info && item.office_contact_info.mobile_no">
                            {{ item.office_contact_info.mobile_no | numberConvert }}
                          </template>
                          <template v-else>N/A</template>
                        </span>
                      </b-td>
                      <b-td>
                        {{ $t('cip.nid_no') + ': ' + $n(item.nid, { useGrouping: false }) }} <br>
                        {{ $t('cip.person_tin') + ': ' + $n(item.tax_info.tin_no, { useGrouping: false }) }} <br>
                        {{ $t('cip.circle') + ': ' + item.tax_info.tax_circle }} <br>
                        {{ $t('cip.org_tin') + ': ' + $n(item.org_tax_info.tin_no, { useGrouping: false }) }} <br>
                        {{ $t('cip.circle') + ': ' + item.org_tax_info.tax_circle }} <br>
                        {{ $t('detailsView.institute_bin') + ': ' + $options.filters.numberConvert(item.org_info.bin_no) }}
                      </b-td>
                      <b-td class="text-center">
                        {{ getProductExportInfo(item) }} <br>
                        {{ getProductExportInfo(item, 2) }}
                      </b-td>
                      <b-td class="text-center"></b-td>
                      <b-td class="text-center">{{ getCountryNames(item.export_destination) }}</b-td>
                      <b-td class="text-center">
                        {{ item.other_compliance && item.other_compliance.dispute_settled === 1 ? $t('globalTrans.yes') : $t('globalTrans.no') }}
                      </b-td>
                      <b-td class="text-center"></b-td>
                    </b-tr>
                  </template>
                  <b-tr v-else>
                    <b-td colspan="9" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <!-- <div class="pagination-wrapper mt-3">
                <b-pagination
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="loadData"
                />
              </div> -->
            </b-col>
        </b-row>
        </b-overlay>
      </b-card>
    </div>
</template>
<script>
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import { scrutinyEvaluationApplicantsApi } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'
import ExportPdf from './evaluation_sheet_pdf'

export default {
  name: 'EvaluationSheet',
  props: ['listItem'],
  components: {
    ListReportHead
  },
  data () {
    return {
      exportTrophyCIPServiceBaseUrl: exportTrophyCIPServiceBaseUrl,
      listData: [],
      fiscalYearId: 0,
      fiscalYearPrevId: 0,
      fiscalYearPrevTwoId: 0,
      circularYear: 0,
      search: {
        limit: 20
      },
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 20,
        slOffset: 1
      },
      AddressHelper: AddressHelper,
      headerExcelDefault: {
        orgName: null,
        orgNameBn: null,
        address: null,
        address_bn: null
      }
    }
  },
  created () {
    this.headerDataExcel()
    if (this.listItem.product_service_sector_id && this.listItem.circular_id) {
      const obj = this.$store.state.ExportTrophyCipService.commonObj.circularList.find(item => item.value === parseInt(this.listItem.circular_id))
      // const fiscalYear = this.fiscalYearList.find(item => item.value === parseInt(this.fiscalYearId))
      this.circularYear = obj.year
      const fiscalYear = this.getFiscalYearFromYear(obj.year)
      this.fiscalYearId = fiscalYear.value
      const fiscalYearPrev = this.fiscalYearList.find(item => item.sorting_order === parseInt(fiscalYear.sorting_order - 1))
      const fiscalYearPrevTwo = this.fiscalYearList.find(item => item.sorting_order === parseInt(fiscalYear.sorting_order - 2))
      this.fiscalYearPrevId = fiscalYearPrev.value
      this.fiscalYearPrevTwoId = fiscalYearPrevTwo.value
      // this.loadData()
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    headerValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
        headerVal[0] = this.$t('globalTrans.government_republic_of_bangladesh')
        headerVal[1] = this.headerExcelDefault.orgName
        headerVal[2] = this.headerExcelDefault.address
        headerVal[3] = this.getReportTitle(this.circularYear)
        headerVal[4] = ''
        headerVal[5] = '(' + this.$n(1) + ') ' + this.$t('externalUser.export_product_sector') + ' : ' + this.productName(this.listItem.product_service_sector_id)
        headerVal[6] = this.getReportSubTitle()
        headerVal[7] = ''
        headerVal[8] = ''
      } else {
        headerVal[0] = this.$t('globalTrans.government_republic_of_bangladesh')
        headerVal[1] = this.headerExcelDefault.orgNameBn
        headerVal[2] = this.headerExcelDefault.address_bn
        headerVal[3] = this.getReportTitle(this.circularYear)
        headerVal[4] = ''
        headerVal[5] = '(' + this.$n(1) + ') ' + this.$t('externalUser.export_product_sector') + ' : ' + this.productName(this.listItem.product_service_sector_id)
        headerVal[6] = this.getReportSubTitle()
        headerVal[7] = ''
        headerVal[8] = ''
      }
      return headerVal
    },
    excelData: function () {
      const list = []
      const headerObj = {}
      let serial1, serial2, orgNameAddress, orgOwnerInfo, exportInfoPrc, exportInfoBbc,
          enteringNewMarket, compliance, comments
      if (this.currentLocale === 'en') {
        serial1 = 'SL'
        serial2 = 'SL '
        orgNameAddress = this.$t('cip.candidate_org_name_address', 'en')
        orgOwnerInfo = this.$t('cip.applicant_nid_tin_bin', 'en')
        exportInfoPrc = this.getExportInfoPrc(this.fiscalYearId)
        exportInfoBbc = this.getExportInfoBbc(this.fiscalYearId)
        enteringNewMarket = this.$t('exportTrophy.entering_new_market', 'en')
        compliance = this.$t('cip.are_trade_disputes', 'en')
        comments = this.$t('globalTrans.comments', 'en')
      } else {
        serial1 = 'ক্রমিক সংখ্যা'
        serial2 = 'ক্রমিক সংখ্যা '
        orgNameAddress = this.$t('cip.candidate_org_name_address', 'bn')
        orgOwnerInfo = this.$t('cip.applicant_nid_tin_bin', 'bn')
        exportInfoPrc = this.getExportInfoPrc(this.fiscalYearId)
        exportInfoBbc = this.getExportInfoBbc(this.fiscalYearId)
        enteringNewMarket = this.$t('exportTrophy.entering_new_market', 'bn')
        compliance = this.$t('cip.are_trade_disputes', 'bn')
        comments = this.$t('globalTrans.comments', 'bn')
      }
      // header
      headerObj[serial1] = ' '
      headerObj[serial2] = ' '
      headerObj[orgNameAddress] = this.$n(1)
      headerObj[orgOwnerInfo] = this.$n(2)
      headerObj[exportInfoPrc] = this.$n(3)
      headerObj[exportInfoBbc] = this.$n(4)
      headerObj[enteringNewMarket] = this.$n(5)
      headerObj[compliance] = this.$n(6)
      headerObj[comments] = this.$n(7)
      list.push(headerObj)
      if (this.listData.length) {
        this.listData.forEach((item, index) => {
          const listItem = {}
          listItem[serial1] = this.$n(index + 1)
          listItem[serial2] = this.$n(index + 1)
          listItem[orgNameAddress] = this.getOrgNameAddressInfo(item)
          listItem[orgOwnerInfo] = this.getOrgOwnerInfo(item)
          listItem[exportInfoPrc] = (this.getProductExportInfo(item) + '\n' + this.getProductExportInfo(item, 2))
          listItem[exportInfoBbc] = ' '
          listItem[enteringNewMarket] = this.getCountryNames(item.export_destination)
          listItem[compliance] = item.other_compliance && item.other_compliance.dispute_settled === 1 ? this.$t('globalTrans.yes') : this.$t('globalTrans.no')
          listItem[comments] = ' '
          list.push(listItem)
        })
      } else {
        const blankRowObj = {}
        blankRowObj[serial1] = ' '
        blankRowObj[serial2] = ' '
        blankRowObj[orgNameAddress] = ' '
        blankRowObj[orgOwnerInfo] = ' '
        blankRowObj[exportInfoPrc] = this.$t('globalTrans.noDataFound')
        blankRowObj[exportInfoBbc] = ' '
        blankRowObj[enteringNewMarket] = ' '
        blankRowObj[compliance] = ' '
        blankRowObj[comments] = ' '
        list.push(blankRowObj)
      }
      return list
    }
  },
  methods: {
    headerDataExcel () {
      RestApi.getData(exportTrophyCIPServiceBaseUrl, '/configuration/report-heading/detail/2').then(response => {
        if (response.success) {
          const orgList = this.$store.state.CommonService.commonObj.componentOrgList.find(item => item.value === 2)
          const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
          const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
          this.headerExcelDefault.orgName = orgName
          this.headerExcelDefault.orgNameBn = orgNameBn
          this.headerExcelDefault.address = response.data.address
          this.headerExcelDefault.address_bn = response.data.address_bn
          this.loadData()
        }
      })
    },
    getSectorName (id) {
      const obj = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadData () {
      const params = Object.assign({ show_all: 1 }, this.search, this.listItem)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, scrutinyEvaluationApplicantsApi, params)
      if (result.success) {
        this.listData = result.data
        this.totalApplication = result.total_application
        this.setExcelData()
      } else {
        this.listData = []
        this.totalApplication = 0
        this.setExcelData()
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getProductExportInfo (item, type = 1) {
      const obj = item.product_success_description[1]
      return type === 1 ? this.$n(parseFloat(obj.export_volume)) + ' ' + this.$t('cip.bel') : this.$n(parseFloat(obj.export_value_ssd)) + ' ' + this.$t('cip.mmd')
    },
    getMinimumIncome (Id) {
      const Obj = this.$store.state.ExportTrophyCipService.commonObj.assignIncomeList.find(item => item.sector_id === parseInt(Id))
      return Obj ? Obj.minimum_income : 0
    },
    productName (Id) {
      const Obj = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.find(item => item.value === parseInt(Id))
      return this.currentLocale === 'bn' ? Obj?.text_bn : Obj?.text_en
    },
    getCountryNames (data) {
      let names = '-'
      if (data.length) {
        const arr = []
        data.forEach(item => {
          const obj = this.$store.state.CommonService.commonObj.countryList.find(el => el.value === parseInt(item.country_id))
          if (obj !== undefined) {
            arr.push(obj.text)
          }
        })
        names = arr.toString()
      }
      return names
    },
    getPresentAddressData (data) {
      const outputData = {
        address_details_en: data.pr_address_details_en,
        address_details_bn: data.pr_address_details_bn,
        ward_id: data.pr_ward_id,
        union_id: data.pr_union_id,
        city_corporation_id: data.pr_city_corporation_id,
        pauroshoba_id: data.pr_paurashava_id,
        upazila_id: data.pr_upazila_id,
        district_id: data.pr_district_id,
        division_id: data.pr_division_id
      }
      return outputData
    },
    getPermanentAddressData (data) {
      const outputData = {
        address_details_en: data.pm_address_details_en,
        address_details_bn: data.pm_address_details_bn,
        ward_id: data.pm_ward_id,
        union_id: data.pm_union_id,
        city_corporation_id: data.pm_city_corporation_id,
        pauroshoba_id: data.pm_paurashava_id,
        upazila_id: data.pm_upazila_id,
        district_id: data.pm_district_id,
        division_id: data.pm_division_id
      }
      return outputData
    },
    pdfExport () {
      const reportTitle = this.currentLocale === 'en' ? 'Export assessment statement of export income and other information of exporters as per application form received for selection of National Export Trophy ' + this.getFiscalYearName(this.fiscalYearId) : 'জাতীয় রপ্তানি ট্রফি ' + this.getFiscalYearName(this.fiscalYearId) + ' নির্বাচনের জন্য প্রাপ্ত আবেদনপত্র অনুযায়ী রপ্তানীকারকগণের রপ্তানি আয় ও অন্যান্য তথ্যাবলীর রপ্তানি মূল্যয়ন বিবরণী'
      return ExportPdf.exportPdfDetails(exportTrophyCIPServiceBaseUrl, '/configuration/report-heading/detail', 2, reportTitle, this)
    },
    setExcelData () {
      this.$emit('get-excel-data', {
        headerValue: this.headerValue,
        headerExcelDefault: this.headerExcelDefault,
        excelData: this.excelData
      })
    },
    getOrgNameAddressInfo (item) {
      const text = (this.currentLocale === 'en' ? item.name_en : item.name_bn) + '\n' +
      (this.currentLocale === 'en' ? item.designation_en : item.designation_bn) + '\n' +
      this.$t('globalTrans.father_name') + ':' + (this.currentLocale === 'en' ? item.father_name_en : item.father_name_bn) + '\n' +
      this.$t('globalTrans.mother_name') + ':' + (this.currentLocale === 'en' ? item.mother_name_en : item.mother_name_bn) + '\n' +
      (item.address ? this.AddressHelper.getCommonAddress(this.getPresentAddressData(item.address)) : '') + '\n' +
      this.$t('globalTrans.permanent_address') + ' : ' + (item.address ? this.AddressHelper.getCommonAddress(this.getPermanentAddressData(item.address)) : 'N/A') + '\n' +
      this.$t('globalTrans.phone_no') + ' : ' +
      (item.contact_info && item.contact_info.telephone_no ? this.$options.filters.numberConvert(item.contact_info.telephone_no) : 'N/A') + '\n' +
      this.$t('globalTrans.mobile') + ' : ' +
      (item.contact_info && item.contact_info.mobile_no ? this.$options.filters.numberConvert(item.contact_info.mobile_no) : 'N/A') + '\n' +
      this.$t('globalTrans.email') + ' : ' +
      (item.contact_info && item.contact_info.email ? item.contact_info.email : 'N/A') + '\n' +
      (this.currentLocale === 'en' ? item.org_info.org_name_en : item.org_info.org_name_bn) + '\n' +
      (item.org_address ? AddressHelper.getCommonAddress(item.org_address) : '') + '\n' +
      this.$t('globalTrans.phone_no') + ' : ' +
      (item.office_contact_info && item.office_contact_info.telephone_no ? this.$options.filters.numberConvert(item.office_contact_info.telephone_no) : 'N/A') + '\n' +
      this.$t('globalTrans.mobile') + ' : ' +
      (item.office_contact_info && item.office_contact_info.mobile_no ? this.$options.filters.numberConvert(item.office_contact_info.mobile_no) : 'N/A') + '\n'
      return text
    },
    getOrgOwnerInfo (item) {
      const text = (this.$t('cip.nid_no') + ': ' + this.$n(item.nid, { useGrouping: false })) + '\n' +
      (this.$t('cip.person_tin') + ': ' + this.$n(item.tax_info.tin_no, { useGrouping: false })) + '\n' +
      (this.$t('cip.circle') + ': ' + item.tax_info.tax_circle) + '\n' +
      this.$t('cip.org_tin') + ': ' + this.$n(item.org_tax_info.tin_no, { useGrouping: false }) + '\n' +
      this.$t('cip.circle') + ': ' + item.org_tax_info.tax_circle + '\n' +
      this.$t('detailsView.institute_bin') + ': ' + this.$options.filters.numberConvert(item.org_info.bin_no)
      return text
    },
    getFiscalYearFromYear (year) {
      const firstYear = parseInt(year) - 1 // 2021
      const secondYear = firstYear - 1 // 2020
      const fiscalYearEn = secondYear + '-' + firstYear
      return this.fiscalYearList.find(item => item.text_en.toLowerCase().trim() === fiscalYearEn.toLowerCase().trim())
    },
    getReportTitle (year) {
      return this.currentLocale === 'en' ? 'Export income and other information of exporters recommended by the Preliminary Selection Committee for CIP (Exports) - ' + year + ' elections' : 'সিআইপি (রপ্তানি) - ' + this.$n(year, { useGrouping: false }) + ' নির্বাচন সংক্রান্ত প্রাথমিক বাছাই কমিটি কর্তৃক সুপারিশকৃত রপ্তানিকারকগণের রপ্তানি আয় ও অন্যান্য তথ্যবিবরণী'
    },
    getReportSubTitle () {
      const obj = this.$store.state.ExportTrophyCipService.commonObj.assignIncomeList.find(item => item.year === parseInt(this.circularYear) && item.sector_id === parseInt(this.listItem.product_service_sector_id))
      const amountText = obj !== undefined ? this.$n(obj.minimum_income) : ''
      return this.currentLocale === 'en' ? 'The minimum export income for a single application is ' + amountText + ' M:M:D:' : 'একক আবেদনের জন্য নূন্যতম রপ্তানি আয় ' + amountText + ' মি:মা:ড:'
    },
    getExportInfoPrc (fiscalYearId) {
      return this.currentLocale === 'en' ? 'Export Volume and Repatriated Earnings in fiscal year ' + this.getFiscalYearName(fiscalYearId) + ' (M:M:D:) (as per PRC)' : this.getFiscalYearName(fiscalYearId) + ' অর্থবছরে রপ্তানির পরিমান ও প্রত্যাবাসিত আয় (মি:মা:ড:) (পিআরসি অনুযায়ী)'
    },
    getExportInfoBbc (fiscalYearId) {
      return this.currentLocale === 'en' ? 'Repatriated Export Earnings in fiscal year ' + this.getFiscalYearName(fiscalYearId) + ' (Millions of Dollars) (According to Bangladesh Bank Online)' : this.getFiscalYearName(fiscalYearId) + ' অর্থবছরে প্রত্যাবাসিত রপ্তানি আয় (মি:মা:ড:) (বাংলাদেশ ব্যাংক এর অনলাইন অনুযায়ী)'
    }
  }
}
</script>
<style scoped>
.list-disc {
  list-style-type: disc;
  padding-left: 1rem;
}
</style>
